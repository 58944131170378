import React, { useContext, useEffect } from 'react';
import '../App.css';
import { useState } from 'react';
import MultipleChoiceQuiz from '../components/MultipleChoiceQuiz';
import VideoPlayer from '../components/VideoPlayer';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom';
import LanguageSet from '../components/language/LanguageSet';
import axios from 'axios';
import LanguageContext from '../components/hooks/LanguageContext';
import MatchingQuiz from '../components/MatchingQuiz';
import SingleChoiceQuizImage from '../components/SingleChoiceQuizImage';
import SingleSelectPdfImage from '../components/SingleSelectPdfImage';
import QuizMultiSelectDragDrop from '../components/QuizMultiSelectDragDrop';
import PdfShow from '../components/PdfShow';
import ImagePdfLinkComponent from '../components/ImagePdfLinkComponent';
import OtherCommingSoon from '../components/OtherCommingSoon';
import ResultOne from '../components/ResultOne';
import ResultTwo from '../components/ResultTwo';
import LoadingSnipQuiz from '../components/LoadingSnipQuiz';
import SingleChoiceQuizImageFraud from '../components/SingleChoiceQuizImageFraud';
import BitcoinAnimation1 from '../components/coins/BitcoinAnimation1';
import BitcoinAnimation2 from '../components/coins/BitcoinAnimation2';
import BitcoinAnimation3 from '../components/coins/BitcoinAnimation3';
import BitcoinAnimation4 from '../components/coins/BitcoinAnimation4';
import BitcoinAnimation5 from '../components/coins/BitcoinAnimation5';
import BitcoinAnimation6 from '../components/coins/BitcoinAnimation6';
import BitcoinAnimation7 from '../components/coins/BitcoinAnimation7';
import BitcoinAnimation8 from '../components/coins/BitcoinAnimation8';
import BitcoinAnimation9 from '../components/coins/BitcoinAnimation9';
import BitcoinAnimation10 from '../components/coins/BitcoinAnimation10';
import BitcoinAnimation11 from '../components/coins/BitcoinAnimation11';
import BitcoinAnimation12 from '../components/coins/BitcoinAnimation12';
import BitcoinAnimation13 from '../components/coins/BitcoinAnimation13';
import BitcoinAnimation14 from '../components/coins/BitcoinAnimation14';
import BitcoinAnimation15 from '../components/coins/BitcoinAnimation15';
import { ToastContainer, toast } from 'react-toastify';
import Ikigai from '../components/Ikigai';
import Game from '../components/snakeLadder/Game'
import SingleSelectRadio from '../components/SingleSelectRadio';
import MultipleChoiceQuizNoImage from '../components/MultipleChoiceQuizNoImage';
import Constants from '../common/Constants';
import { useAuth } from '../providers/AuthContext';

const { useRef } = React;
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;



function QuestionPage() {
  const navigate = useNavigate()
  const { state } = useLocation();
  const { user, logout: authLogout, setMyPoints } = useAuth(); // Use useAuth
  const [currentQuestion,] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOptionsMcq, setSelectedOptionsMcq] = useState([]);
  const { language } = useContext(LanguageContext);
  const [minTime, setMinTime] = useState(0)
  const [played, setPlayed] = useState(0);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false);
  const [subModule, setSubModule] = useState(null)
  const [nextSubModule, setNextSubModule] = useState(null)
  const [sectionTitle, setSectionTitle] = useState({ english: "", hindi: "" })
  const [previousSubModule, setPreviousSubModule] = useState(null)
  const [answer, setAnswer] = useState(null)
  const [isMarked, setIsMarked] = useState(0)

  const [moduleId, setModuleId] = useState(null)
  const [courseId, setCourseId] = useState(null)
  const [itemId, setItemId] = useState(null)
  const [ItemTitle, setItemTitle] = useState(null)
  const [questionTitle, setQuestionTitle] = useState(null)
  const [moduleType, setModuleType] = useState(null)
  const [nextSection, setNextSection] = useState(false)
  const [nextSectionName, setNextSectionName] = useState(null)

  const [videoBtnDisabled, setVideoBtnDisabled] = useState(true)



  // Two bucket drag and drop question 
  const [rightBucket, setRightBucket] = useState([]);
  const [wrongBucket, setWrongBucket] = useState([]);

  //can you share that
  const [showResult1, setShowResult1] = useState(false)
  const [showResult2, setShowResult2] = useState(false)

  //matching question drag and drop
  const [dragDropLength, setDragDropLength] = useState(0)
  const [buckets, setBuckets] = useState([]);
  const [answerDragDrop, setAnswerDragDrop] = useState(null)
  // const [backet1, setBucket1] = useState([]);
  // const [backet2, setBucket2] = useState([]);
  // const [backet3, setBucket3] = useState([]);
  // const [backet4, setBucket4] = useState([]);
  // const [backet5, setBucket5] = useState([]);
  // const [backet6, setBucket6] = useState([]);
  //IKIGAI
  const [textInputs, setTextInputs] = useState([]);
  const [textInputsAns, setTextInputsAns] = useState([]);
  //pdf mark as done enable disable
  const [pdfCount, setPdfCount] = useState(0)
  const [pdfMinTime, setPdfMinTime] = useState(0)
  const [pdfBtnDisabled, setPdfBtnDisabled] = useState(true)

  //Single Choice Quiz
  const [progress, setProgress] = useState(0)

  //snake and ladder status
  const [snakeLadderWon, setSnakeLadderWon] = useState(false)

  // const []

  // console.log(language)
  const childRef1 = useRef();
  const childRef2 = useRef();
  const childRef3 = useRef();
  const childRef4 = useRef();
  const childRef5 = useRef();
  const childRef6 = useRef();
  const childRef7 = useRef();
  const childRef8 = useRef();
  const childRef9 = useRef();
  const childRef10 = useRef();
  const childRef11 = useRef();
  const childRef12 = useRef();
  const childRef13 = useRef();
  const childRef14 = useRef();
  const childRef15 = useRef();

  // console.log(played)
  // let url, title;
  let url, url2;
  if (state && state.url !== undefined && state.title !== undefined) {
    url = state.url;
    url2 = state.url2
    // title = state.title;
  } else {
    // Handle the case where state or its properties are undefined
    // You can assign default values or show an error message here
    navigate("/home")
  }
  const [apiUrl, setApiUrl] = useState(url)


  // useEffect(() => {
  //   // Set the page title when the component mounts
  //   document.title = 'My Page Title';

  //   // Optionally, you can reset the title when the component unmounts
  //   return () => {
  //     document.title = 'Original Page Title';
  //   };
  // }, []); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingQuiz(true);

        let newUrl = apiUrl + "&page=" + currentPage;
        const response = await axios.get(newUrl);
        console.log(response.data)
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          if (response.data.course_module_item.length > 0) {
            // Set state based on the first API response
            setSubModule(response.data);
            let total = response.data.summary.total_page;
            let nextModule = response.data.summary.next_module_url;
            let preModule = response.data.summary.previous_module_url;
            let section_title = response.data.summary.section_title;
            setNextSubModule(nextModule);
            setPreviousSubModule(preModule);
            setTotalCount(total);
            setSectionTitle(section_title);
            let course_id = response.data.course_module_item[0].course_id;
            let module_id = response.data.course_module_item[0].module_id;
            let item_id = response.data.course_module_item[0].id;
            let item_title = response.data.course_module_item[0].title["english"];
            let module_type = response.data.course_module_item[0].question_type;

            setModuleId(module_id);
            setCourseId(course_id);
            setItemId(item_id);
            setItemTitle(item_title);
            setModuleType(module_type);

            if (
              response.data.course_module_item[0].question_type === "single_choice" ||
              response.data.course_module_item[0].question_type === "single_choice_imag" ||
              response.data.course_module_item[0].question_type === "quiz-pdf-mcq"
            ) {
              let question_title = response.data.course_module_item[0].question_title["english"];
              setQuestionTitle(question_title);
            }

            if (response.data.course_module_item[0].question_type === "drag-drop") {
              setDragDropLength(response.data.course_module_item[0].options.length);
            } else if (response.data.course_module_item[0].question_type === "ikigai") {
              setTextInputs(response.data.course_module_item[0].question_placeholder);
            }
          } else {
            // navigate("/comming-soon");
            let total = response.data.summary.total_page;
            let nextModule = response.data.summary.next_module_url;
            let preModule = response.data.summary.previous_module_url;
            let section_title = response.data.summary.section_title;
            setNextSubModule(nextModule);
            setPreviousSubModule(preModule);
            setTotalCount(total);
            setSectionTitle(section_title);
            setSubModule({
              course_module_item: [
                {
                  course_id: courseId,
                  module_id: "",
                  id: "",
                  title: { english: "", hindi: "" },
                  question_type: "other",
                },
              ],
              summary: {
                next_module_url: nextModule,
                previous_module_url: preModule,
                pageSize: 1,
                total: 1,
                total_page: 1,
                page_title: nextSectionName,
                section_title: { english: "", hindi: "" },
              },
            });

          }
        }

        // Second API request using data from the first response
        let subUrl = apiUrl.split("?");
        // let newUrl2 = "${Constants.BASE_URL}/course-module-item-list-response?" + subUrl[1] + "&page=" + currentPage + "&access_token=" + user?.token;
        let newUrl2 = `${Constants.BASE_URL}/course-module-item-list-response?${subUrl[1]}&page=${currentPage}&access_token=${user?.token}`;
        const response2 = await axios.post(newUrl2);

        console.log("response2.data", response2.data)
        if (response2.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          if (response2.data.course_module_item.length > 0) {
            // Set state based on the second API response
            // console.log("urnnnnn", response2.data.course_module_item[0].is_marked)
            setAnswer(response2.data);
            setIsMarked(response2.data.course_module_item[0].is_marked);

            if (response.data.course_module_item[0].question_type === "drag-drop") {
              setAnswerDragDrop(response2.data.course_module_item[0]);
            } else if (response2.data.course_module_item[0].question_type === "ikigai") {
              if (response2.data.course_module_item[0].marked_options !== null) {
                setTextInputsAns(response2.data.course_module_item[0].marked_options);
              }
            }
          } else {
            // navigate("/comming-soon");
            let total = response.data.summary.total_page;
            let nextModule = response.data.summary.next_module_url;
            let preModule = response.data.summary.previous_module_url;
            let section_title = response.data.summary.section_title;
            setNextSubModule(nextModule);
            setPreviousSubModule(preModule);
            setTotalCount(total);
            setSectionTitle(section_title);
            setSubModule({
              course_module_item: [
                {
                  course_id: courseId,
                  module_id: "",
                  id: "",
                  title: { english: "", hindi: "" },
                  question_type: "other",
                },
              ],
              summary: {
                next_module_url: nextModule,
                previous_module_url: preModule,
                pageSize: 1,
                total: 1,
                total_page: 1,
                page_title: nextSectionName,
                section_title: { english: "", hindi: "" },
              },
            });
          }
        }

        const response3 = await axios.post(`${API_STUDENT_PROGRESS}?access_token=${user?.token}`);
        if (response3.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setMyPoints(response3.data.student_summary.points);
          setProgress(response3.data.student_summary.section_active_progress.progress_percentage);
        }
        // console.log(`${url2}&access_token=${user?.token}`)
        const response4 = await axios.post(`${url2}&access_token=${user?.token}`);
        if (response4.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setProgress(response4.data.course_summary.progress);
        }

        setLoadingQuiz(false);
      } catch (error) {
        console.error(error);
        toast.error(error?.message);
        setLoadingQuiz(false);
      }
    };

    fetchData();
  }, [currentPage, apiUrl, navigate,  setMyPoints, user?.token, url2]); // Include all dependencies in the array


  const handleOptionSelect = (optionIndex, question_type) => {
    switch (question_type) {
      case "single_choice":
        setSelectedOptions([optionIndex]);
        break;
      case "mcq":
        setSelectedOptionsMcq((prevOptions) =>
          prevOptions.includes(optionIndex)
            ? prevOptions.filter((index) => index !== optionIndex)
            : [...prevOptions, optionIndex]
        );
        break;
      default:
        break;
    }
  };


  const ResetResponse = () => {
    setSelectedOptions([])
  }


  const handlePreviousClick = () => {
    // console.log(previousSubModule)
    setMinTime(0)
    setPlayed(0)
    setCount(1)
    setVideoBtnDisabled(true)
    //for pdf 
    setPdfCount(0)
    setPdfMinTime(0)
    setPdfBtnDisabled(true)
    //Reset Selected Options
    setSelectedOptions({})
    setSelectedOptionsMcq([])
    if (previousSubModule === null) {

      return navigate(-1)
    }

    if (currentPage !== 1) {
      // console.log("less")
      setCurrentPage(currentPage - 1)
    } else if (currentPage === 1) {
      // console.log("equal")
      setApiUrl(previousSubModule)
      setCurrentPage(1)
    } else {
      // console.log("next")
      setApiUrl(previousSubModule)
      setCurrentPage(1)
    }

  };

  const resetPdfState = () => {
    setPdfCount(0);
    setPdfMinTime(0);
    setPdfBtnDisabled(true);
  };

  const handleNextClick = () => {
    // Reset video and PDF-related state variables
    setMinTime(0);
    setPlayed(0);
    setCount(1);
    setVideoBtnDisabled(true);

    // For PDF
    setPdfCount(0);
    setPdfMinTime(0);
    setPdfBtnDisabled(true);

    // Reset Selected Options
    setSelectedOptions({});
    setSelectedOptionsMcq([]);

    // Extract relevant data for readability
    const moduleTitle = subModule.course_module_item[0].title["english"];
    const sectionTitle = subModule.summary.section_title["english"];

    if (progress < 80 && moduleTitle === 'Security vs Privacy ' && sectionTitle === 'Digital Safety') {
      navigate(-1);
    } else if (progress < 80 && moduleTitle === 'Test PDF' && sectionTitle === 'Digital Well-Being') {
      navigate(-1);
    } else if (progress < 80 && moduleTitle === 'How to Stop Fake News' && sectionTitle === 'Digital Information Ecosystem') {
      navigate(-1);
    } else if (progress < 80 && moduleTitle === 'Security vs Privacy ' && sectionTitle === 'Digital Safety') {
      navigate(-1);
    } else if (nextSection) {
      const nextSectionMap = {
        'Digital Well-Being': '/module/digital-wellbeing',
        'Digital Information Ecosystem': '/module/digital-infoecosystem',
        'Digital Agency': '/module/digital-agency',
      };

      navigate(nextSectionMap[nextSectionName], {
        state: {
          url: subModule.summary.next_module_url,
          page_title: subModule.summary.page_title,
        },
      });
    } else if (nextSubModule === null && sectionTitle === 'Digital Safety') {
      setNextSectionAndNavigate(subModule.summary.next_section_name, 1);
    } else if (nextSubModule === null && sectionTitle === 'Digital Well-Being') {
      setNextSectionAndNavigate(subModule.summary.next_section_name, 2);
    } else if (currentPage === totalCount && nextSubModule === null && sectionTitle === 'Digital Information Ecosystem') {
      setNextSectionAndNavigate(subModule.summary.next_section_name, 3);
    } else if (nextSubModule === null && sectionTitle === 'Digital Agency') {
      setNextSectionAndNavigate(subModule.summary.next_section_name, 4);
    } else if (currentPage < totalCount) {
      setCurrentPage(currentPage + 1);
    } else if (currentPage === totalCount) {
      setApiUrlAndNavigate(nextSubModule, 1);
    } else {
      setApiUrlAndNavigate(nextSubModule, 1);
    }
  };

  const setNextSectionAndNavigate = (nextSectionName, courseId) => {
    if(courseId === 4){
      navigate("/endline") 
    }else{
      setSubModule({
        course_module_item: [
          {
            course_id: courseId,
            module_id: "",
            id: "",
            title: { english: "", hindi: "" },
            question_type: "thank_you",
          },
        ],
        summary: {
          next_module_url: subModule.summary.next_section_url,
          previous_module_url: subModule.summary.current_module_url,
          pageSize: 1,
          total: 1,
          total_page: 1,
          page_title: nextSectionName,
        },
      });
      setNextSection(true);
      setNextSectionName(nextSectionName);
    }

  };
  const setApiUrlAndNavigate = (url, page) => {
    setApiUrl(url);
    setCurrentPage(page);
  };




  const MarkAsDoneQuiz = (type) => {
    // console.log(type)
    if (type === "video") {
      setLoading(true)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)

      axios.post(newUrl).then((response) => {
        // console.log("Video Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    } else if (type === "pdf") {
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      setLoading(true)
      axios.post(newUrl).then((response) => {
        // console.log("pdf Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    } else if (type === "single_choice") {
      setLoading(true)
      // console.log(selectedOptions)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${questionTitle}&option_id=${selectedOptions.id}&option_title=${selectedOptions.title}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("single_choice Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setAnswer(response.data)
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }

      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    }
    else if (type === "quiz-pdf-mcq") {
      // console.log(selectedOptions)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${questionTitle}&option_id=${selectedOptions.id}&option_title=${selectedOptions.title}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        setLoading(true)
        // console.log("single_choice Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          if (Number(response.data.course_module_item[0].correct_option.id) === Number(response.data.course_module_item[0].option_marked.id)) {
            ShowMyPoints()
            CoinAnimate(response.data.course_module_item[0].points)
          }
          setAnswer(response.data)
          setIsMarked(response.data.course_module_item[0].is_marked)

          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    } else if (type === "single_choice_image") {
      setLoading(true)
      // console.log(selectedOptions)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${questionTitle}&option_id=${selectedOptions.id}&option_title=${selectedOptions.title["english"]}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("single_choice Mark as Done:", response.data.course_module_item[0].correct_option.id);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          if (Number(response.data.course_module_item[0].correct_option.id) === Number(response.data.course_module_item[0].option_marked.id)) {
            CoinAnimate(response.data.course_module_item[0].points)
            ShowMyPoints()
          }
          setAnswer(response.data)
          setIsMarked(response.data.course_module_item[0].is_marked)
          setLoading(false)
        }

      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    } else if (type === "single_select_radio") {
      setLoading(true)
      // console.log(selectedOptions)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${questionTitle}&option_id=${selectedOptions.id}&option_title=${selectedOptions.title["english"]}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("single_choice Mark as Done:", response.data.course_module_item[0].correct_option.id);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          if (Number(response.data.course_module_item[0].correct_option.id) === Number(response.data.course_module_item[0].option_marked.id)) {
            CoinAnimate(response.data.course_module_item[0].points)
            ShowMyPoints()
          }
          setAnswer(response.data)
          setIsMarked(response.data.course_module_item[0].is_marked)
          setLoading(false)
        }

      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    }
    else if (type === "imagemap") {
      setLoading(true)
      // console.log(selectedOptions)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("image map Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setAnswer(response.data)
          if (currentPage === totalCount) {
            setIsMarked(response.data.course_module_item[0].is_marked)
            CoinAnimate(response.data.course_module_item[0].points)
            ShowMyPoints()
          }


          setLoading(false)
        }

      }).catch((err) => {
        console.log(err)
        // toast.error(err?.message);
        setLoading(false)
      });
    } else if (type === "quiz-pdf-drag-drop") {
      // console.log(selectedOptions)
      setLoading(true)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("quiz-pdf-drag-drop:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setAnswer(response.data)
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }

      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    } else if (type === "drag-drop") {
      setLoading(true)
      // console.log(selectedOptions)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("drag-drop:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setAnswerDragDrop(response.data.course_module_item[0])
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }

      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    } else if (type === "mcq") {
      // console.log(selectedOptionsMcq)
      setLoading(true)
      const selectedOptions = selectedOptionsMcq.toString();

      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&options=${selectedOptions}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        console.log("image map Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setAnswer(response.data)
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    }
    else if (type === "multi_select_radio") {
      // console.log(selectedOptionsMcq)
      setLoading(true)
      const selectedOptions = selectedOptionsMcq.toString();
      // console.log(selectedOptions)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&options=${selectedOptions}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("image map Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setAnswer(response.data)
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    }
    else if (type === "ikigai") {
      // console.log(selectedOptionsMcq)
      setLoading(true)
      const selectedOptions = selectedOptionsMcq.toString();
      // console.log(textInputs)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&placeholders=${JSON.stringify(textInputs)}&module_type=${moduleType}&access_token=${user?.token}`
      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("image map Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setTextInputsAns(response.data.course_module_item[0].marked_options)
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    }
    else if (type === "snake_and_ladder") {
      // console.log(selectedOptionsMcq)
      setLoading(true)
      const selectedOptions = selectedOptionsMcq.toString();
      // console.log(textInputs)
      let newUrl = `${Constants.BASE_URL}/course-module-item-markasdone?course_id=${courseId}&module_id=${moduleId}&item_id=${itemId}&item_title=${ItemTitle}&module_type=${moduleType}&access_token=${user?.token}`

      // console.log(newUrl)
      axios.post(newUrl).then((response) => {
        // console.log("image map Mark as Done:", response.data);
        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          // setTextInputsAns(response.data.course_module_item[0].marked_options)
          setIsMarked(response.data.course_module_item[0].is_marked)
          CoinAnimate(response.data.course_module_item[0].points)
          ShowMyPoints()
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err?.message);
        setLoading(false)
      });
    }
  }

  const CoinAnimate = (count) => {
    // console.log(count)
    switch (count) {
      case 1:
        childRef1.current.addCoin1();
        break;
      case 2:
        childRef2.current.addCoin2();
        break;
      case 3:
        childRef3.current.addCoin3();
        break;
      case 4:
        childRef4.current.addCoin4();
        break;
      case 5:
        childRef5.current.addCoin5();
        break;
      case 6:
        childRef6.current.addCoin6();
        break;
      case 7:
        childRef7.current.addCoin7();
        break;
      case 8:
        childRef8.current.addCoin8();
        break;
      case 9:
        childRef9.current.addCoin9();
        break;
      case 10:
        childRef10.current.addCoin10();
        break;
      case 11:
        childRef11.current.addCoin11();
        break;
      case 12:
        childRef12.current.addCoin12();
        break;
      case 13:
        childRef13.current.addCoin13();
        break;
      case 14:
        childRef14.current.addCoin14();
        break;
      case 15:
        childRef15.current.addCoin15();
        break;
      default:
        return null;
    }
  }

  const ShowMyPoints = async () => {
    // console.log("points called")
    try {
      // setLoadingQuiz(true)

      const response2 = await axios.post(`${API_STUDENT_PROGRESS}?access_token=${user?.token}`);
      // console.log("Answer Response JSON:", response2.data);
      if (response2.data.message === "Invalid Access Token") {
        authLogout()
        navigate("/");
      } else {
        // console.log("Show Mypoints", response2.data.student_summary.points)
        setMyPoints(response2.data.student_summary.points)
        setProgress(response2.data.student_summary.section_active_progress.progress_percentage)
        // setLoadingQuiz(false)
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.message);
      // setLoadingQuiz(false)
    }

  }
  useEffect(() => {
    videoDisabled()

  }, [count, isMarked])
  // check video mark as done disabled

  const videoDisabled = () => {
    // console.log("count", count, "minTime", minTime)
    if (count >= minTime) {

      setVideoBtnDisabled(false)
    } else if (isMarked === 1) {
      // console.log("isMarked", isMarked)
      setVideoBtnDisabled(true)
    } else {
      // console.log("else", count, minTime)
      setVideoBtnDisabled(true)
    }
  }



  useEffect(() => {
    pdfDisabled()

  }, [pdfCount, isMarked])

  const pdfDisabled = () => {
    if (pdfCount >= pdfMinTime) {
      setPdfBtnDisabled(false)
    } else if (isMarked === 1) {
      setPdfBtnDisabled(true)
    } else {
      setPdfBtnDisabled(true)
    }
  }
  const renderQuestion = (question) => {
    let courseId = question.course_id
    //  console.log(courseId)
    // Implement logic to render each question type here
    switch (question.question_type) {
      case "video":
        // Implement the video question component here
        return (<VideoPlayer
          question={question}
          language={language}
          setMinTime={setMinTime}
          // setVideoTotalTime={setVideoTotalTime}
          setPlayed={setPlayed}
          setCount={setCount}
          count={count}
          played={played}
        />);

      case "pdf":
        // let url = `https://docs.google.com/gview?url=${question.pdf_url}`
        // console.log(url)
        // Implement the PDF question component here
        // return <iframe  src={url} style={{width:"600px", height:"500px"}} frameBorder="0" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"/>
        // console.log(question.file_path[language])const [pdfCount , setPdfCount] =  useState(1)
        // const [pdfMinTime , setPdfMinTime] 
        return (<PdfShow question={question} setPdfCount={setPdfCount} setPdfMinTime={setPdfMinTime} pdfCount={pdfCount} />);
      // return <div style={{display:"flex", justifyContent:"center"}}><embed  src={question.file_path[language]}   /></div>
      case "imagemap":
        return (<ImagePdfLinkComponent
          question={question}
          selectedOptions={selectedOptions}
          handleNextClick={handleNextClick}
          MarkAsDoneQuiz={MarkAsDoneQuiz}
        />);

      case "single_choice":
        // console.log(question)
        // Implement the single-choice question component heresfe
        return (<SingleChoiceQuizImage
          question={question}
          handleOptionSelect={handleOptionSelect}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          language={language}
          ResetResponse={ResetResponse}
          answer={answer}
          isMarked={isMarked}
        />);

      case "single_choice_image":
        // console.log(question)
        // Implement the single-choice question component heresfe
        return (<SingleChoiceQuizImageFraud
          question={question}
          handleOptionSelect={handleOptionSelect}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          language={language}
          ResetResponse={ResetResponse}
          answer={answer}
          isMarked={isMarked}
        />);

      case "single_select_radio":
        return (<SingleSelectRadio
          question={question}
          handleOptionSelect={handleOptionSelect}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          language={language}
          ResetResponse={ResetResponse}
          answer={answer}
          isMarked={isMarked}
        />
        );

      case "mcq":
        // Implement the multiple-choice question component here
        return (<MultipleChoiceQuiz
          question={question}
          handleOptionSelect={handleOptionSelect}
          selectedOptionsMcq={selectedOptionsMcq}
          setSelectedOptionsMcq={setSelectedOptionsMcq}
          answer={answer}
          isMarked={isMarked}
        />
        );


      case "multi_select_radio":
        // Implement the multiple-choice question component here
        return (<MultipleChoiceQuizNoImage
          question={question}
          handleOptionSelect={handleOptionSelect}
          selectedOptionsMcq={selectedOptionsMcq}
          setSelectedOptionsMcq={setSelectedOptionsMcq}
          answer={answer}
          isMarked={isMarked}
        />
        );


      case "drag-drop":
        // Implement the multiple-choice question component here
        return (<MatchingQuiz
          question={question}
          language={language}
          isMarked={isMarked}
          setBuckets={setBuckets}
          buckets={buckets}
          answerDragDrop={answerDragDrop}

        // backet1={backet1}
        // setBucket1={setBucket1}

        // backet2={backet2}
        // setBucket2={setBucket2}


        // backet3={backet3}
        // setBucket3={setBucket3}

        // backet4={backet4}
        // setBucket4={setBucket4}

        // backet5={backet5}
        // setBucket5={setBucket5}

        // backet6={backet6}
        // setBucket6={setBucket6}




        />
        );

      case "quiz-pdf-mcq":
        // Implement the multiple-choice question component here
        return (<SingleSelectPdfImage
          question={question}
          language={language}
          handleOptionSelect={handleOptionSelect}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          ResetResponse={ResetResponse}
          answer={answer}
          isMarked={isMarked}
        />
        );

      case "quiz-pdf-drag-drop":
        return (<QuizMultiSelectDragDrop
          question={question}
          isMarked={isMarked}
          rightBucket={rightBucket}
          setRightBucket={setRightBucket}
          wrongBucket={wrongBucket}
          setWrongBucket={setWrongBucket}

        />
        );
      case "other":
        return (<OtherCommingSoon question={question} />);

      case "result_one":
        return (<ResultOne
          question={question}
          language={language}
          answer={answer}
          showResult1={showResult1}
          setShowResult1={setShowResult1}
        />
        );
      case "result_two":
        return (<ResultTwo
          question={question}
          language={language}
          answer={answer}
          showResult2={showResult2}
          setShowResult2={setShowResult2}
        />
        );
      case "ikigai":
        return (<Ikigai
          question={question}
          language={language}
          setTextInputs={setTextInputs}
          textInputs={textInputs}
          textInputsAns={textInputsAns}
          setTextInputsAns={setTextInputsAns}
          isMarked={isMarked}
        />
        );
      case "snake_and_ladder":
        return <Game onStateChange={setSnakeLadderWon} question={question} language={language} />
      case "thank_you":
        // console.log(question)
        if (Number(courseId) === 1) {
          return (<div className="row">
            <div className="col-12">

              <div className="thanksmsg my-4">
                {language === "english" && <div onClick={() => { navigate("/myprogress") }}><img src={require("../assets/images/thankyou1.png")} alt="" width="100%" /></div>}
                {language === "hindi" && <div onClick={() => { navigate("/myprogress") }}><img src={require("../assets/images/thankyou2.png")} alt="" width="100%" /></div>}
              </div>
            </div>
          </div>
          );
        } else if (Number(courseId) === 2) {
          return (<div className="row">
            <div className="col-12">

              <div className="thanksmsg my-4">
                {language === "english" && <div onClick={() => { navigate("/myprogress") }}><img src={require("../assets/images/section2/thankyou1.png")} alt="" width="100%" /></div>}
                {language === "hindi" && <div onClick={() => { navigate("/myprogress") }}><img src={require("../assets/images/section2/thankyou2.png")} alt="" width="100%" /></div>}
              </div>
            </div>
          </div>
          );
        } else if (Number(courseId) === 3) {
          return (<div className="row">
            <div className="col-12">

              <div className="thanksmsg my-4">
                {language === "english" && <div onClick={() => { navigate("/myprogress") }}><img src={require("../assets/images/section3/thankyou1.png")} alt="" width="100%" /></div>}
                {language === "hindi" && <div onClick={() => { navigate("/myprogress") }}><img src={require("../assets/images/section3/thankyou2.png")} alt="" width="100%" /></div>}
              </div>
            </div>
          </div>
          );
        } else if (Number(courseId) === 4) {
          return (<div className="row">
            <div className="col-12">

              <div className="thanksmsg my-4">
                {language === "english" && <div onClick={() => { navigate("/endline") }}><img src={require("../assets/images/section4/thankyou1.png")} alt="" width="100%" /></div>}
                {language === "hindi" && <div onClick={() => { navigate("/endline") }}><img src={require("../assets/images/section4/thankyou2.png")} alt="" width="100%" /></div>}
              </div>
            </div>
          </div>
          );
        }
        break
      default: return null;
    }
  };
  // console.log(subModule)
  // console.log(selectedOptionsMcq.length)
  // console.log("bucket length", buckets.length)

  const CheckIkagiValues = () => {
    const allTextHaveValue = textInputs.every(item => item.text && item.text.trim() !== '');
    // console.log(allTextHaveValue)
    return allTextHaveValue
  }
  // console.log(count)
  return (
    <div id="body_bg">
      <Header page_title={sectionTitle[language]}  />
      <LanguageSet />
      {loadingQuiz ? (<LoadingSnipQuiz />)
        : (
          <>
            <div className="container-fluid px-0">
              <div className="row justify-content-center">
                <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12">
                  {subModule && <section className="my-3">
                    <div className="container-fluid">
                      <>
                        {/* {loadingQuiz ? <LoadingSnipQuiz /> : renderQuestion(subModule.course_module_item[currentQuestion])} */}
                        {renderQuestion(subModule.course_module_item[currentQuestion])}

                        <div className="row">
                          <div className="col-12">
                            <div className="mark-buttons d-flex justify-content-between">
                              <div className="left-btn"> <div
                                onClick={handlePreviousClick}
                                disabled={currentQuestion === 0}
                                className="previous_btn"
                              >
                                <FontAwesomeIcon icon={faArrowLeft} size="lg" color="#fff" />
                              </div>
                              </div>
                              {
                                subModule.course_module_item[currentQuestion].question_type === "video"
                                  ?
                                  <div className="markButtons">
                                    <button
                                      className="d-flex align-items-center gap-2"
                                      style={{ backgroundColor: isMarked === 1 ? "#792110" : videoBtnDisabled === false ? "#F3DB7B" : "#C8C8C8", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                      onClick={() => {
                                        MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)
                                      }}
                                      disabled={isMarked === 1 ? true : videoBtnDisabled === true ? true : loading ? true : false}
                                    >
                                      {language === "english" ? "I have watched the video" : "मैंने ये वीडियो देख लिया है "}
                                      {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                    </button>
                                  </div>
                                  :
                                  subModule.course_module_item[currentQuestion].question_type === "pdf"
                                    ? <div className="markButtons">
                                      <button className="d-flex align-items-center gap-2"
                                        onClick={() => {
                                          MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)
                                        }}
                                        style={{ backgroundColor: isMarked === 1 ? "#792110" : pdfBtnDisabled === false ? "#F3DB7B" : "#C8C8C8", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                        disabled={isMarked === 1 ? true : pdfBtnDisabled === true ? true : loading ? true: false}
                                      >
                                        {language === "english" ? "I have read the information" : "मैंने ये पीडीएफ पढ़ लिया है"}
                                        {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                      </button>
                                    </div>
                                    : (currentPage < totalCount && subModule.course_module_item[currentQuestion].question_type === "imagemap") ? ""

                                      : (currentPage === totalCount && subModule.course_module_item[currentQuestion].question_type === "imagemap") ?
                                        <div className="markButtons">
                                          <button className="d-flex align-items-center gap-2"
                                            disabled={isMarked === 1 ? true : loading ? true : false}
                                            onClick={() => {
                                              MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)
                                            }}
                                            style={{ backgroundColor: isMarked === 1 ? "#792110" : "#f3db7b", color: isMarked === 1 ? "#ffffff" : "#454545" }}

                                          >{language === "english" ? `Submit Answer` : "उत्तर जमा करें"}
                                            {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                          </button> </div>
                                        : subModule.course_module_item[currentQuestion].question_type === "thank_you" ? ""
                                          : subModule.course_module_item[currentQuestion].question_type === "result_one" ?
                                            <div className="markButtons"> <button
                                              className="d-flex align-items-center gap-2"
                                              onClick={() => {
                                                // console.log("open modal")
                                                setShowResult1(true)
                                              }}
                                              style={{ backgroundColor: "#F3DB7B", color: "#454545" }}
                                            >{language === "english" ? "Learn more" : "और सीखें"}</button> </div>
                                            : subModule.course_module_item[currentQuestion].question_type === "result_two" ?
                                              <div className="markButtons"> <button
                                                className="d-flex align-items-center gap-2"
                                                onClick={() => {
                                                  // console.log("open modal")
                                                  setShowResult2(true)
                                                }}
                                                style={{ backgroundColor: "#F3DB7B", color: "#454545" }}
                                              >{language === "english" ? "Learn more" : "और सीखें"}</button> </div>
                                              : subModule.course_module_item[currentQuestion].question_type === "quiz-pdf-drag-drop" ?
                                                <div className="markButtons"> <button
                                                  className="d-flex align-items-center gap-2"

                                                  disabled={isMarked === 1 ? true : ((Object.keys(wrongBucket).length === 3) && (Object.keys(rightBucket).length === 3)) ? false : loading ? true : true}
                                                  onClick={() => {
                                                    MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)
                                                  }}
                                                  style={{ backgroundColor: isMarked === 1 ? "#792110" : ((Object.keys(wrongBucket).length === 3) && (Object.keys(rightBucket).length === 3)) ? "#F3DB7B" : "#C8C8C8", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                >{language === "english" ? "Submit Answer" : "उत्तर जमा करें"}
                                                  {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                </button> </div>
                                                : subModule.course_module_item[currentQuestion].question_type === "drag-drop" ?
                                                  <div className="markButtons"> <button
                                                    className="d-flex align-items-center gap-2"

                                                    disabled={isMarked === 1 ? true : (Number(buckets.length) === Number(dragDropLength)) ? false : loading ? true : true}
                                                    onClick={() => {
                                                      MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)
                                                    }}
                                                    style={{ backgroundColor: isMarked === 1 ? "#792110" : ((Number(buckets.length) === Number(dragDropLength))) ? "#F3DB7B" : "#C8C8C8", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                  >{language === "english" ? "Submit Answer" : "उत्तर जमा करें"}
                                                    {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                  </button> </div>
                                                  : (subModule.course_module_item[currentQuestion].question_type === "mcq") ?
                                                    <div className="markButtons"> <button
                                                      className="d-flex align-items-center gap-2"
                                                      disabled={isMarked === 1 ? true : selectedOptionsMcq.length === 0 ? true : loading ? true : false}
                                                      onClick={() => {
                                                        MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)
                                                      }}
                                                      style={{ backgroundColor: isMarked === 1 ? "#792110" : Number(selectedOptionsMcq.length) === 0 ? "#C8C8C8" : "#F3DB7B", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                    >{language === "english" ? "Mark as Done" : "उत्तर जमा करें"}
                                                      {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                    </button> </div>
                                                    : (subModule.course_module_item[currentQuestion].question_type === "multi_select_radio") ?
                                                      <div className="markButtons"> <button
                                                        className="d-flex align-items-center gap-2"
                                                        disabled={isMarked === 1 ? true : selectedOptionsMcq.length === 0 ? true : loading ? true : false}
                                                        onClick={() => {
                                                          MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)
                                                        }}
                                                        style={{ backgroundColor: isMarked === 1 ? "#792110" : Number(selectedOptionsMcq.length) === 0 ? "#C8C8C8" : "#F3DB7B", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                      >{language === "english" ? "Mark as Done" : "उत्तर जमा करें"}
                                                        {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                      </button> </div>

                                                      : (subModule.course_module_item[currentQuestion].question_type === "single_choice_image") ?
                                                        <div className="markButtons"> <button
                                                          className="d-flex align-items-center gap-2"

                                                          disabled={isMarked === 1 ? true : Object.keys(selectedOptions).length === 0 ? true : loading ? true : false}
                                                          onClick={() => {
                                                            MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)

                                                          }}
                                                          style={{ backgroundColor: isMarked === 1 ? "#792110" : Object.keys(selectedOptions).length === 0 ? "#C8C8C8" : "#F3DB7B", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                        >{language === "english" ? "Mark as Done" : "उत्तर जमा करें"}
                                                          {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                        </button>
                                                        </div>
                                                        : (subModule.course_module_item[currentQuestion].question_type === "single_select_radio") ?
                                                          <div className="markButtons"> <button
                                                            className="d-flex align-items-center gap-2"

                                                            disabled={isMarked === 1 ? true : Object.keys(selectedOptions).length === 0 ? true : loading ? true : false}
                                                            onClick={() => {
                                                              MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)

                                                            }}
                                                            style={{ backgroundColor: isMarked === 1 ? "#792110" : Object.keys(selectedOptions).length === 0 ? "#C8C8C8" : "#F3DB7B", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                          >{language === "english" ? "Mark as Done" : "उत्तर जमा करें"}
                                                            {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                          </button>
                                                          </div>
                                                          : (subModule.course_module_item[currentQuestion].question_type === "ikigai") ?
                                                            <div className="markButtons"> <button
                                                              className="d-flex align-items-center gap-2"
                                                              disabled={isMarked === 1 ? true : CheckIkagiValues() === false ? true : loading ? true : false}
                                                              onClick={() => {
                                                                MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)

                                                              }}
                                                              style={{ backgroundColor: isMarked === 1 ? "#792110" : CheckIkagiValues() === false ? "#C8C8C8" : "#F3DB7B", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                            >
                                                              {language === "english" ? "Submit Answer" : "उत्तर जमा करें"}{CheckIkagiValues}
                                                              {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                            </button> </div>

                                                            : (subModule.course_module_item[currentQuestion].question_type === "snake_and_ladder") ?
                                                              <div className="markButtons"> <button
                                                                className="d-flex align-items-center gap-2"
                                                                disabled={isMarked === 1 ? true : snakeLadderWon ? false : loading ? true : true}
                                                                onClick={() => {
                                                                  MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)

                                                                }}
                                                                style={{ backgroundColor: isMarked === 1 ? "#792110" : snakeLadderWon ? "#792110" : "#8C8C8C", color: isMarked === 1 ? "#ffffff" : snakeLadderWon ? "#ffffff" : "#454545" }}
                                                              >
                                                                {language === "english" ? "Submit Answer" : "उत्तर जमा करें"}
                                                                {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                              </button> </div>

                                                              : <div className="markButtons"> <button
                                                                className="d-flex align-items-center gap-2"

                                                                disabled={isMarked === 1 ? true : Object.keys(selectedOptions).length === 0 ? true : loading ? true : false}
                                                                onClick={() => {
                                                                  MarkAsDoneQuiz(subModule.course_module_item[currentQuestion].question_type)

                                                                }}
                                                                style={{ backgroundColor: isMarked === 1 ? "#792110" : Object.keys(selectedOptions).length === 0 ? "#C8C8C8" : "#F3DB7B", color: isMarked === 1 ? "#ffffff" : "#454545" }}
                                                              >
                                                                {language === "english" ? "Submit Answer" : "उत्तर जमा करें"}
                                                                {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                              </button> </div>
                              }
                              <div className="right-btn">
                                <div
                                  className="previous_btn"
                                  onClick={
                                    (subModule.course_module_item[currentQuestion].question_type === "thank_you" && subModule.course_module_item[currentQuestion].course_id !== 4)
                                      ? () => { navigate("/myprogress") } : (subModule.course_module_item[currentQuestion].question_type === "thank_you" && subModule.course_module_item[currentQuestion].course_id === 4) ?
                                        () => { navigate("/endline") } : handleNextClick}
                                >
                                  <FontAwesomeIcon icon={faArrowRight} size="lg" color="#fff" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>

                    </div>
                  </section>
                  }
                </div>
              </div>
            </div>
            <BitcoinAnimation1 ref={childRef1} />
            <BitcoinAnimation2 ref={childRef2} />
            <BitcoinAnimation3 ref={childRef3} />
            <BitcoinAnimation4 ref={childRef4} />
            <BitcoinAnimation5 ref={childRef5} />
            <BitcoinAnimation6 ref={childRef6} />
            <BitcoinAnimation7 ref={childRef7} />
            <BitcoinAnimation8 ref={childRef8} />
            <BitcoinAnimation9 ref={childRef9} />
            <BitcoinAnimation10 ref={childRef10} />
            <BitcoinAnimation11 ref={childRef11} />
            <BitcoinAnimation12 ref={childRef12} />
            <BitcoinAnimation13 ref={childRef13} />
            <BitcoinAnimation14 ref={childRef14} />
            <BitcoinAnimation15 ref={childRef15} />
          </>
        )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div >
  )
}

export default QuestionPage;